<template>
    <my-drawer v-model="show" :style="{ width: '100%' }" :with-nav="false" class="PoolDetail" position="right">
        <my-alert title="操作提示">
            <span v-if="row && row.operateStatus" slot="message">
                {{ row.operateStatus | getMapText('number/operateStatus') }}
            </span>
        </my-alert>
        <my-table v-if="row">
            <my-table-row :value="row.number" text="号码" />
            <my-table-row :value="row.levelNum" text="号码质量">
                <template #value>{{ row.levelNum | getMapText('number/levelNum') }}</template>
            </my-table-row>
            <my-table-row :value="row.sectionCode" text="号段">
                <template #value>{{ row.sectionCode | getMapText('number/sections') }}</template>
            </my-table-row>
            <my-table-row :value="row.typeCode" text="号码类型" />
            <my-table-row :value="row.numClass" text="号码种类">
                <my-label slot="value"
                          background="#E6EFFF"
                          color="#1677FF"
                          size="mini"
                >{{ row.numClass | getMapText('number/numClass') }}</my-label>
            </my-table-row>
            <my-table-row :value="row.callinMinFee" text="呼入月低消费" />
            <my-table-row :value="row.numStatus" text="号码状态">
                <template #value>{{ row.numStatus | getMapText('number/numStatus') }}</template>
            </my-table-row>
            <template v-if="destineInfo">
                <template v-if="operateStatus == 4">
                    <my-table-row :value="destineInfo.destineEndTime" text="预占到期时间" />
                    <my-table-row :value="destineInfo.orderStatus" text="号码使用状态">
                        <span slot="value">{{ destineInfo.orderStatus | getMapText('number/orderStatus') }}</span>
                    </my-table-row>
                    <my-table-row :value="destineInfo.destineStaff" text="预占员工">
                        <span slot="value">{{ destineInfo.destineStaff }}</span>
                    </my-table-row>
                    <my-table-row :value="destineInfo.destineRemark" text="预占备注" />
                </template>
                <template v-if="operateStatus == 9">
                    <my-table-row :value="destineInfo.destineTime" text="预占申请时间" />
                    <my-table-row :value="destineInfo.destineRemark" text="预占申请备注" />
                </template>
            </template>
        </my-table>
        <div v-if="operateStatus == '4,5'" class="PoolDetail__tips">当前版本暂不支持「协商申请」，请移步至「合作伙伴管理系统」完成相关操作。</div>
        <template v-if="row" #footer>
            <van-button block type="default" @click="$router.back()">返回</van-button>
            <van-button v-if="operateStatus == 1"
                        :disabled="cubeNumber == 0"
                        block
                        type="info"
                        @click="onPreCheck"
            >预查询（剩余量{{ cubeNumber }}）</van-button>
            <van-button v-else-if="operateStatus == 3"
                        :disabled="cubeNumber == 0"
                        block
                        type="info"
                        @click="onDestine"
            >申请预占（剩余量{{ cubeNumber }}）</van-button>
            <van-button v-else-if="operateStatus == 4 && occupiedState"
                        block
                        type="info"
                        @click="onRelease"
            >释放</van-button>
            <van-button v-else-if="operateStatus == 9 && occupiedState"
                        block
                        type="info"
                        @click="onPreCancel"
            >取消申请</van-button>
        </template>
    </my-drawer>
</template>

<script>
    import {MyTable, MyTableRow, MyAlert, MyDrawer, MyLabel} from "@/components/MyPlugins";
    import {mapState, mapMutations} from "vuex";
    import {getNumberInfo, addPreCheck, numberDestine, preCancel, numberDestineInfo} from "@/api/number";

    export default {
        name: "PoolDetail",
        components: {
            MyTable,
            MyTableRow,
            MyAlert,
            MyDrawer,
            MyLabel
        },
        data() {
            return {
                show: false,
                row: {destineOrganCode:''},
                destineInfo: null
            }
        },
        computed: {
            ...mapState('number', ['numClass', 'dataCube']),
            ...mapState('user', ['accountInfo']),
            // 操作状态
            operateStatus() {
                if (!this.row) return null;
                let {operateStatus} = this.row;
                if (operateStatus && operateStatus.length > 0) {
                    if (operateStatus.length == 1) return operateStatus[0];
                    return operateStatus.join(',');
                }
                return 0;
            },
            // 根据类型获取配额(坑)
            cubeNumber() {
                let {inquiryCountMax = 0, inquiryCount = 0, destineMax = 0, desiningCount = 0, desinedCount = 0} = this.dataCube || {};
                // 预查询
                if (this.operateStatus == 1) return inquiryCountMax - inquiryCount;
                // 预占
                if (this.operateStatus == 3) {
                    return destineMax - desiningCount - desinedCount;
                }
                return 0;
            },
            // 本人操作
            occupiedState() {
              return this.accountInfo.userCode == this.row.destineOrganCode;
            },
        },
        mounted() {
            this.show = true;
            this.$nextTick(this.getNumberRow);
        },
        beforeRouteLeave(to, from, next) {
            this.show = false;
            this.row = null;
            next();
        },
        methods: {
            ...mapMutations('common', ['updateRow']),
            // 获取号码详情
            getNumberRow() {
                this.$toast.loading({message: '数据刷新中', forbidClick: true, duration: 0});
                let {number} = this.$route.query;
                getNumberInfo({number}).then(res => {
                    this.row = res.data;
                    // 获取已预占，预占申请信息
                    if (['4','9'].includes(this.operateStatus) && this.occupiedState) {
                        numberDestineInfo({number}).then(o => {
                            this.destineInfo = o.data;
                            this.$toast.clear();
                        });
                    } else {
                        this.$toast.clear();
                    }
                });
            },
            afterUpdate(status) {
                this.row.operateStatus = [status];
                this.updateRow(this.row);
                this.$router.replace({path: '/number-list'});
            },
            // 预查询
            onPreCheck() {
                let {number} = this.row;
                this.$toast.loading({message: '正在预查询', forbidClick: true, duration: 0});
                addPreCheck({number}).then(() => {
                    this.$toast.clear();
                    this.afterUpdate(2);
                });
            },
            // 预占
            onDestine() {
                let {number} = this.row;
                this.$prompt(`确定要预占号码「${number}」吗？`, '预占申请', {
                    inputType: 'textarea',
                    inputPlaceholder: '请输入预占备注信息',
                    confirmButtonText: '提交预占',
                    placement: 'bottom',
                    beforeClose: (action, instance, done) => {
                        if (action == 'confirm') {
                            instance.confirmButtonLoading = true;
                            numberDestine({number, remark: instance.inputValue}).then(() => {
                                instance.confirmButtonLoading = false;
                                done();
                            }).catch(() => {
                                instance.confirmButtonLoading = false;
                            });
                        } else {
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    }
                }).then(() => {
                    this.$toast({
                        message: '预占申请成功',
                        icon: 'checked',
                        onClose: () => {
                            this.afterUpdate(9);
                        }
                    });
                });
            },
            // 取消预占申请
            onPreCancel() {
                let {number} = this.row;
                this.$confirm('确定要取消预占申请吗？', '取消预占申请', {
                    placement: 'bottom',
                    beforeClose: (action, instance, done) => {
                        if (action == 'confirm') {
                            instance.confirmButtonLoading = true;
                            preCancel({number}).then(() => {
                                instance.confirmButtonLoading = false;
                                done();
                            }).catch(() => {
                                instance.confirmButtonLoading = false;
                            });
                        } else {
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    }
                }).then(() => {
                    this.$toast({
                        message: '申请取消成功',
                        icon: 'checked',
                        onClose: () => {
                            this.afterUpdate(3);
                        }
                    });
                });
            },
            onRelease() {
                let {number} = this.row;
                this.$confirm('确定要释放号码吗？？', '号码释放', {
                    placement: 'bottom',
                    beforeClose: (action, instance, done) => {
                        if (action == 'confirm') {
                            instance.confirmButtonLoading = true;
                            preCancel({number}).then(() => {
                                instance.confirmButtonLoading = false;
                                done();
                            }).catch(() => {
                                instance.confirmButtonLoading = false;
                            });
                        } else {
                            instance.confirmButtonLoading = false;
                            done();
                        }
                    }
                }).then(() => {
                    this.$toast({
                        message: '号码释放成功',
                        icon: 'checked',
                        onClose: () => {
                            this.afterUpdate(3);
                        }
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .PoolDetail {
        &__tips {
            color: #B4B9BF;
            margin: 16px;
            font-size: 12px;
        }
    }
</style>
